<template>
	<div class="wrap">
		<div class="header">
			<div class="top top_mafi f-left">
				<div class="util">
					<button
						class="arrow_white"
						@click="$emit('goBack')"
					>
						<i class="icon icon-arrow-left"></i>
					</button>
				</div>
				<h2 class="page_tit">Google OTP</h2>
			</div>
		</div>
		<div class="subindex_wrap pt-50">
			<div class="container">
				<div class="subindex_mainbox type2">

					<h3>Google OTP 인증</h3>
					<div>
						<p class="step_tit">① Google OTP 인증 앱을 스마트폰에 설치하세요.</p>
						<div class="btn_area">
							<a
								class="btn_l btn_fill_blue otp_btn"
								href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=ko&gl=US"
								target="store"
							>
								<img :src="require('@/assets/image/ico_google.png')" alt="">
							</a>
							<a
								class="btn_l btn_fill_blue otp_btn"
								href="https://apps.apple.com/kr/app/google-authenticator/id388497605"
								target="store"
							>
								<img :src="require('@/assets/image/ico_app.png')" alt="">
							</a>
						</div>
					</div>

					<div>
						<p class="step_tit">② Google Authenticator 앱에서 바코드를 스캔하세요.</p>
						<figure class="qr_area">
							<qr-code :text="text_qr_code" :size="160" style="display: inline-block;"></qr-code>
							<figcaption>바코드 스캔이 불가능한 경우 아래키를 입력하세요.</figcaption>
						</figure>
						<div class="bright_input flex pt-20">
							<input
								v-model="gogle_otp_key"
								type="text" readonly
							>
							<button class="btn_l btn_fill_blue ml-10 flex_shrink" @click="copy">복사</button>
						</div>
					</div>

					<div>
						<p class="step_tit">③ Google OTP 인증번호</p>
						<div class="bright_input">
							<input
								v-model="gogle_otp_certfc_number"
								maxlength="6"
								type="text" placeholder="6자리 인증번호 입력"
							>
						</div>
					</div>

					<div class="btn_area">
						<button
							class="btn_l btn_fill_blue"
							:disabled="gogle_otp_certfc_number.length != 6"

							@click="postOtp"
						>확인</button>
					</div>

				</div>
			</div>
		</div>

		<PIN
			v-if="is_pin"
			:options="pin_option"
			style="position: fixed; left: 0; top: 0; width: 100%; height: 100%; z-index: 999999"

			@click="pinClick"
			@cancel="pinCancel"
		></PIN>
	</div>
</template>

<script>

	import PIN from "@/view/Auth/mafia007";

	export default {
		name: 'googleOtp'
		, components: {PIN}
		, data: function(){
			return {
				program: {
					name: '구글 OTP'
					, title: 'Goggle OTP'
					, not_header: true
					, not_footer: true
					, type: 'mypage'
				}
				, is_pin: false
				,pin_option: {
					pin_type: 'set'
				}
				, gogle_otp_key: ''
				, gogle_otp_certfc_number: ''
				, nickname: ''
				, member_number: ''
			}
		}

		, computed: {
			text_qr_code: function(){
				let t = ''
				if(this.member_number && this.gogle_otp_key){
					t = 'otpauth://totp/METAMAFIA(' + this.member_number + ')?secret=' + this.gogle_otp_key
				}

				return t
			}
		}
		, methods: {
			postOtp: async function(){
				this.$log.console('postOtp')
				try{
					this.$bus.$emit('on', true)
					if(!this.gogle_otp_certfc_number){
						throw 'OTP 번호를 입력하세요'
					}
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_google_otp
						, data: {
							gogle_otp_certfc_number: this.gogle_otp_certfc_number
						}
						, type: true
					})

					if(result.success){
						this.is_pin = true
					}else{
						throw result.message
					}

				}catch (e) {
					this.$log.console(e)
					this.$bus.$emit('notify',  { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			,pinClick: async function(){
				this.pin_option.pin_type = 'set'
				this.$emit('to', { name: 'mafia010'})
			}
			,pinCancel: function(){
				this.$log.console('cancel')
				this.is_pin = false
				this.pin_option.pin_type = 'set'
			}
			, getGoogleOtpKey: async function(){
				this.$log.console('getGoogleOtpKey')
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_google_otp_key
						, data: {
						}
						, type: true
					})

					if(result.success){
						this.$log.console(result.data)
						this.gogle_otp_key = result.data.gogle_otp_key
						this.nickname = result.data.nickname
						this.member_number = result.data.member_number
					}else{
						throw result.message
					}

				}catch (e) {
					this.$log.console(e)
					this.$bus.$emit('notify',  { type: 'error', message: e})
					this.$bus.$emit('to', {name: 'join'})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			,clipBoard: function (val){
				const t = document.createElement("textarea");
				document.body.appendChild(t);

				t.value = val;
				this.$log.console('t.value', t.value)
				t.select();
				t.setSelectionRange(0, 9999)
				document.execCommand('copy');
				document.body.removeChild(t);
			}

			,copy: function (){

				this.clipBoard(this.gogle_otp_key);
				this.$bus.$emit('notify',  { type: 'success', message: 'Google OTP 키가 복사되었습니다'})
			}

			, toGoogle: function(){

			}

		}
		, created() {
			this.$bus.$emit('onLoad', this.program)
			this.getGoogleOtpKey()
		}
	}
</script>